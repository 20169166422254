import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import appConfig from '@/plugins/mdAppConfig'
import rules from '@/plugins/rules'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import moment from 'moment'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(rules)
Vue.use(appConfig)

Vue.prototype.$eventHub = new Vue()
Vue.prototype.moment = moment

new Vue({
  router,
  store,
  i18n,
  vuetify,
  moment,
  render: h => h(App),
}).$mount('#app')
